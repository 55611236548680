import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'thousandsForm' })
export class ThousandsFormPipe implements PipeTransform {
  transform(value: any): string {
    if (isNaN(value)) {
      return 'NaN';
    }
    value = Number(value);
    if (!value) {
      return '0';
    }

    let strNumber = value.toString();

    if (strNumber.includes('.')) {
      // To use toFixed(2) only if number isn't whole
      strNumber = Number(strNumber).toFixed(2);
    }

    return strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
