<div id="invoice-body" style="display: none">
  <div
    *ngFor="let document of documentsToPrint; let i = index"
    [ngStyle]="{ 'padding-top': i === 0 ? 0 : '2rem' }"
  >
    <div class="document">
      <div class="header flex">
        <div class="left">
          <p class="h1 w7">{{ document.header.left1 }}</p>
          <p class="h3">{{ document.header.left2 }}</p>
        </div>

        <div class="right">
          <!-- <p class="h0 w7">{{ document.header.right }}</p> -->
          <div class="image">
            <img [src]="document.header.right" />
          </div>
        </div>
      </div>

      <div class="items">
        <div class="flex">
          <div class="start wide">
            <p class="h3 w5">Beschreibung</p>
          </div>
          <div class="middle narrow">
            <p class="h3 w5">Verbuchungsdatum</p>
          </div>
          <div class="end narrow">
            <p class="h3 w5">Betrag</p>
          </div>
        </div>
        <hr />
        <div class="flex m-y" *ngFor="let element of document.elements">
          <div class="start wide">
            <p class="h4 w5">{{ element.left1 }}</p>
            <p class="h4 w5">
              {{ element.left2 }}
            </p>
            <p class="h4 w5">
              <span>{{ element.left3 }}</span>
              <span *ngIf="element.left4" style="margin: 0 0.2rem">•</span>
              <span>{{ element.left4 }}</span>
            </p>
            <p class="h4 w5">
              {{ element.left5 }}
            </p>

            <p class="h4 w5">{{ element.left6 }}</p>
          </div>
          <div class="middle narrow">
            <p class="h4 w5">{{ element.middle }}</p>
          </div>
          <div class="end narrow">
            <p class="h4 w5" [ngClass]="{ plus: element.right > 0 }">
              <span *ngIf="element.right > 0">+</span>
              {{ element.right | thousandsForm }} €
            </p>
          </div>
        </div>
        <hr />
        <!-- <div class="flex">
          <div class="start wide"></div>
          <div class="middle narrow"></div>
          <div class="end narrow">
            <p class="h3 w7">{{ document.total | thousandsForm }} €</p>
          </div>
        </div> -->
      </div>

      <hr />
      <div class="footer flex">
        <div class="left">
          <p class="h4 w6">{{ document.footer.left1 }}</p>
          <p class="h4">{{ document.footer.left2 }}</p>
          <p class="h4">
            <span> {{ document.footer.left3 }} </span>
            <span *ngIf="document.footer.left4" style="margin: 0 0.2rem">
              •
            </span>
            <span> {{ document.footer.left4 }} </span>
          </p>
        </div>

        <div class="right">
          <p class="h4">{{ document.footer.right1 }}</p>
          <p class="h4">{{ document.footer.right2 }}</p>
          <p class="h4">{{ document.footer.right3 }}</p>
        </div>
      </div>

      <div
        *ngIf="i !== documentsToPrint.length - 1"
        style="break-after: page"
      ></div>
    </div>
  </div>

  <div style="break-after: page"></div>

  <div class="div" class="height: 1rem"></div>
  <hr />
  <div class="flex" *ngIf="documentsToPrint.length > 0">
    <p class="h4 w5">Dein alter Kontostand</p>
    <p
      class="h4 w5"
      [ngClass]="{ plus: documentsToPrint[0].currentBalance > 0 }"
    >
      <span *ngIf="documentsToPrint[0].currentBalance > 0">+</span>
      {{ documentsToPrint[0].currentBalance }} €
    </p>
  </div>

  <div class="flex">
    <p class="h4 w5">Ausgehende Transaktionen</p>
    <p class="h4 w5" [ngClass]="{ plus: positiveSum > 0 }">
      <span *ngIf="positiveSum > 0">+</span>
      {{ positiveSum }} €
    </p>
  </div>

  <div class="flex">
    <p class="h4 w5">Einkommende Transaktionen</p>
    <p class="h4 w5">{{ negativeSum }} €</p>
  </div>

  <hr />

  <div class="flex">
    <p class="h4 w5">Dein neuer Kontostand</p>
    <p class="h4 w5" [ngClass]="{ plus: total > 0 }">
      <span *ngIf="total > 0">+</span>{{ total }} €
    </p>
  </div>
</div>
