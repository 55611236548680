<div id="invoice-body" style="display: none">
  <div
    *ngFor="let document of documentsToPrint; let i = index"
    [ngStyle]="{ 'padding-top': i === 0 ? 0 : 0 }"
  >
    <div class="document">
      <div class="header flex">
        <div class="left">
          <p class="h2 w7">{{ document.header.left1 }}</p>
          <div class="div" style="height: 10px"></div>
          <p class="h4">{{ document.header.left2 }}</p>
        </div>

        <div class="right">
          <!-- <p class="h0 w7">{{ document.header.right }}</p> -->
          <div class="image">
            <img [src]="document.header.right" />
          </div>
        </div>
      </div>

      <div class="items">
        <div class="flex">
          <div class="start wide">
            <p class="h3s w5">Beschreibung</p>
          </div>
          <div class="middle narrow">
            <p class="h3s w5">Verbuchungsdatum</p>
          </div>
          <div class="end narrow">
            <p class="h3s w5">Betrag</p>
          </div>
        </div>
        <hr />
        <div class="flex m-y" *ngFor="let element of document.elements">
          <div class="start wide">
            <p class="h3 w5 nomargin nowrap">{{ element.left1 }}</p>
            <p class="h4 w5 nomargin nowrap">
              {{ element.left2 }}
            </p>
            <p class="h4 w5 nomargin nowrap">
              <span>{{ element.left3 }}</span>
              <span *ngIf="element.left4" style="margin: 0 0.2rem">•</span>
              <span>{{ element.left4 }}</span>
            </p>
            <p class="h4 w5 nomargin nowrap">
              {{ element.left5 }}
            </p>

            <p class="h4 w5 nomargin nowrap">{{ element.left6 }}</p>
          </div>
          <div class="middle narrow">
            <p class="h3 w5">{{ element.middle }}</p>
          </div>
          <div class="end narrow">
            <p class="h3 w5" [ngClass]="{ plus: element.right > 0 }">
              <span *ngIf="element.right > 0">+</span>
              {{ element.right | thousandsForm }} €
            </p>
          </div>
        </div>
      </div>

      <div class="filler"></div>
      <hr />
      <div class="footer flex">
        <div class="left">
          <p class="h4 w6">{{ document.footer.left1 }}</p>
          <p class="h4">{{ document.footer.left2 }}</p>
          <p class="h4">
            <span> {{ document.footer.left3 }} </span>
            <span *ngIf="document.footer.left4" style="margin: 0 0.2rem">
              •
            </span>
            <span> {{ document.footer.left4 }} </span>
          </p>
        </div>

        <div class="right">
          <p class="h4">{{ document.footer.right1 }}</p>
          <p class="h4">{{ document.footer.right2 }}</p>
          <p class="h4">{{ document.footer.right3 }}</p>
        </div>
      </div>
      <div class="page-number">
        {{ i + 1 }} / {{ documentsToPrint.length + 3 }}
      </div>
      <div
        *ngIf="i !== documentsToPrint.length - 1"
        style="break-after: page"
      ></div>
    </div>
  </div>

  <!--  -->
  <div style="break-after: page"></div>

  <!-- <div class="div" style="height: 1rem"></div> -->

  <div class="document">
    <div class="header flex" *ngIf="documentsToPrint.length > 0">
      <div class="left">
        <p class="h2 w7">{{ documentsToPrint[0].totalLeft1 }}</p>
        <div class="div" style="height: 10px"></div>
        <p class="h4">{{ documentsToPrint[0].totalLeft2 }}</p>
      </div>

      <div class="right">
        <!-- <p class="h0 w7">{{ document.header.right }}</p> -->
        <div class="image">
          <img [src]="documentsToPrint[0].header.right" />
        </div>
      </div>
    </div>

    <div class="items">
      <div class="flex">
        <div class="start wide">
          <p class="h3s w5">Beschreibung</p>
        </div>
      </div>
      <hr />
      <div class="flex m-y" *ngIf="documentsToPrint.length > 0">
        <p class="h4 w5">Dein alter Kontostand</p>
        <p
          class="h4 w5"
          [ngClass]="{ plus: documentsToPrint[0].currentBalance > 0 }"
        >
          <span *ngIf="documentsToPrint[0].currentBalance > 0">+</span>
          {{ documentsToPrint[0].currentBalance | thousandsForm }} €
        </p>
      </div>

      <div class="flex">
        <p class="h4 w5">Ausgehende Transaktionen</p>
        <p class="h4 w5" [ngClass]="{ plus: positiveSum > 0 }">
          <span *ngIf="positiveSum > 0">+</span>
          {{ positiveSum | thousandsForm }} €
        </p>
      </div>

      <div class="flex">
        <p class="h4 w5">Einkommende Transaktionen</p>
        <p class="h4 w5">{{ negativeSum | thousandsForm }} €</p>
      </div>

      <hr />

      <div class="flex">
        <p class="h4 w5">Dein neuer Kontostand</p>
        <p class="h4 w5" [ngClass]="{ plus: total > 0 }">
          <span *ngIf="total > 0">+</span>{{ total | thousandsForm }} €
        </p>
      </div>
    </div>
    <div class="filler"></div>

    <hr />
    <div class="footer flex" *ngIf="documentsToPrint.length > 0">
      <div class="left">
        <p class="h4 w6">{{ documentsToPrint[0].footer.left1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.left2 }}</p>
        <p class="h4">
          <span> {{ documentsToPrint[0].footer.left3 }} </span>
          <span
            *ngIf="documentsToPrint[0].footer.left4"
            style="margin: 0 0.2rem"
          >
            •
          </span>
          <span> {{ documentsToPrint[0].footer.left4 }} </span>
        </p>
      </div>

      <div class="right">
        <p class="h4">{{ documentsToPrint[0].footer.right1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right2 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right3 }}</p>
      </div>
    </div>

    <div class="page-number">
      {{ documentsToPrint.length + 1 }} / {{ documentsToPrint.length + 3 }}
    </div>
  </div>

  <!--  -->

  <div style="break-after: page"></div>

  <!-- <div class="div" style="height: 1rem"></div> -->

  <div class="document" *ngIf="documentsToPrint.length > 0">
    <div class="header flex">
      <div class="left">
        <p class="h2 w7">{{ documentsToPrint[0].header.left1 }}</p>
        <div class="div" style="height: 10px"></div>
        <p class="h4">{{ documentsToPrint[0].header.left2 }}</p>
      </div>

      <div class="right">
        <!-- <p class="h0 w7">{{ documentsToPrint[0].header.right }}</p> -->
        <div class="image">
          <img [src]="documentsToPrint[0].header.right" />
        </div>
      </div>
    </div>

    <div class="items">
      <div class="flex">
        <div class="start wide">
          <p class="h3 w5">Anmerkung</p>
        </div>
      </div>
      <hr />
      <div class="flex m-y">
        <p class="height-text">
          {{ documentsToPrint[0].freeText1 }}
        </p>
      </div>
    </div>

    <div class="filler"></div>
    <hr />
    <div class="footer flex">
      <div class="left">
        <p class="h4 w6">{{ documentsToPrint[0].footer.left1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.left2 }}</p>
        <p class="h4">
          <span> {{ documentsToPrint[0].footer.left3 }} </span>
          <span
            *ngIf="documentsToPrint[0].footer.left4"
            style="margin: 0 0.2rem"
          >
            •
          </span>
          <span> {{ documentsToPrint[0].footer.left4 }} </span>
        </p>
      </div>

      <div class="right">
        <p class="h4">{{ documentsToPrint[0].footer.right1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right2 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right3 }}</p>
      </div>
    </div>
    <div class="page-number">
      {{ documentsToPrint.length + 2 }} / {{ documentsToPrint.length + 3 }}
    </div>
  </div>

  <!--  -->

  <div style="break-after: page"></div>

  <!-- <div class="div" style="height: 1rem"></div> -->

  <div class="document" *ngIf="documentsToPrint.length > 0">
    <div class="header flex">
      <div class="left">
        <p class="h2 w7">{{ documentsToPrint[0].header.left1 }}</p>
        <div class="div" style="height: 10px"></div>
        <p class="h4">{{ documentsToPrint[0].header.left2 }}</p>
      </div>

      <div class="right">
        <!-- <p class="h0 w7">{{ documentsToPrint[0].header.right }}</p> -->
        <div class="image">
          <img [src]="documentsToPrint[0].header.right" />
        </div>
      </div>
    </div>

    <div class="items">
      <div class="flex">
        <div class="start wide">
          <p class="h3 w5">Anmerkung</p>
        </div>
      </div>
      <hr />
      <div class="flex m-y">
        <p class="height-text">
          {{ documentsToPrint[0].freeText2 }}
        </p>
      </div>

      <div class="flex m-y">
        <p class="height-text">
          {{ documentsToPrint[0].freeText3 }}
        </p>
      </div>

      <div class="flex m-y">
        <p class="height-text">
          {{ documentsToPrint[0].freeText4 }}
        </p>
      </div>

      <div class="flex m-y">
        <p class="height-text" style="color: grey">
          {{ documentsToPrint[0].freeText5 }}
        </p>
      </div>
    </div>

    <div class="filler"></div>
    <hr />
    <div class="footer flex">
      <div class="left">
        <p class="h4 w6">{{ documentsToPrint[0].footer.left1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.left2 }}</p>
        <p class="h4">
          <span> {{ documentsToPrint[0].footer.left3 }} </span>
          <span
            *ngIf="documentsToPrint[0].footer.left4"
            style="margin: 0 0.2rem"
          >
            •
          </span>
          <span> {{ documentsToPrint[0].footer.left4 }} </span>
        </p>
      </div>

      <div class="right">
        <p class="h4">{{ documentsToPrint[0].footer.right1 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right2 }}</p>
        <p class="h4">{{ documentsToPrint[0].footer.right3 }}</p>
      </div>
    </div>
    <div class="page-number">
      {{ documentsToPrint.length + 3 }} / {{ documentsToPrint.length + 3 }}
    </div>
  </div>
</div>
