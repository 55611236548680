import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Document } from './document.interface';

@Injectable({ providedIn: 'root' })
export class PrintStatementsService {
  private printOrdersSubject = new Subject<Document>();

  getPrintOrderSubject() {
    return this.printOrdersSubject.asObservable();
  }

  print(document: Document) {
    this.printOrdersSubject.next(document);
  }
}
