import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrintStatementsService } from '../print-statements.service';
import { Document, DocumentElement } from '../document.interface';

@Component({
  selector: 'app-print-bank-statements',
  templateUrl: './print-bank-statements.component.html',
  styleUrls: ['./print-bank-statements.component.scss'],
})
export class PrintBankStatementsComponent implements OnInit, OnDestroy {
  printOrdersSubscription: Subscription | null = null;
  invoiceBody: any;
  documentsToPrint: Document[] = [];
  positiveSum = 0;
  negativeSum = 0;
  total = 0;
  constructor(private printStatementsService: PrintStatementsService) {}

  ngOnInit(): void {
    this.invoiceBody = document.getElementById('invoice-body');

    this.printOrdersSubscription = this.printStatementsService
      .getPrintOrderSubject()
      .subscribe((document: Document) => {
        this.positiveSum = 0;
        this.negativeSum = 0;
        this.total = 0;
        this.documentsToPrint = [];

        for (let i = 0; i < document.elements.length; i = i + 6) {
          const elements: DocumentElement[] = [];
          for (let j = i; j < i + 6; j++) {
            const element = document.elements[j];
            if (element) {
              elements.push(element);
              if (element.right > 0) {
                this.positiveSum += element.right;
              } else {
                this.negativeSum += element.right;
              }
            }
          }

          const documentToPush: Document = {
            header: document.header,
            footer: document.footer,
            elements,
            currentBalance: document.currentBalance,
            totalLeft1: document.totalLeft1,
            totalLeft2: document.totalLeft2,
            freeText1: document.freeText1,
            freeText2: document.freeText2,
            freeText3: document.freeText3,
            freeText4: document.freeText4,
            freeText5: document.freeText5,
          };

          this.documentsToPrint.push(documentToPush);
        }

        this.positiveSum = Number(this.positiveSum.toFixed(2));
        this.negativeSum = Number(this.negativeSum.toFixed(2));
        document.currentBalance = Number(document.currentBalance.toFixed(2));

        this.total = Number(
          (
            this.positiveSum +
            this.negativeSum +
            document.currentBalance
          ).toFixed(2)
        );

        setTimeout(() => this.print(), 2000);
      });
  }

  print() {
    if (!this.invoiceBody) {
      this.invoiceBody = document.getElementById('invoice-body');
    }
    const printContent = this.invoiceBody.innerHTML;
    const popupWin = window.open('', '_blank', 'width=1000,height=800');
    popupWin?.document.open();
    popupWin?.document.write(
      `<html><head>` +
        `<link href="https://db.onlinewebfonts.com/c/4a0c1d6164df2c0aa88da1437cd38ef1?family=Calibre+Web" rel="stylesheet">` +
        `
         <style>


          * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
          }

     	    @media print {
            body {
              -webkit-print-color-adjust: exact;
              font-family: "Calibre Web";
            }

            p {
              font-family: "Calibre Web";
            }
          }

          body {
            padding: 0 1.65rem;
            max-width: 1000px;
            margin: 0 0.5rem;
          }

          .document {
            // height: 98vh;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0.5rem 0 0.5rem 0;
          }

          .document .filler {
            flex: 1;
          }

          .flex {
            display: flex;
            justify-content: space-between;
            // align-items: center;
          }

          .items .flex .wide {
            width: 500px;
          }

          .items .flex .narrow {
            width: 250px;
          }

          .items {
            margin: 1.25rem 0 0.1rem 0;
          }

          .m-y {
            margin: 0.75rem 0;
          }

          .start {
            flex: 3.75;
          }

          .middle {
            flex: 2.5;
            text-align: end;
          }

          .end {
            flex: 2;
            text-align: end;
          }

          .start, .middle, .end {
            margin: 0 0.1rem;
          }

          p {
            margin: 0.2rem 0;
            font-family: "Calibre Web";
            color: #3e3e3e;
          }

          p.nowrap {
            // white-space: nowrap;
          }

          hr {
            width: 100%;
          }

          .nomargin {
            margin: 0;
          }

          .h0 {
            font-size: 3.3rem;
          }

          .h1 {
            font-size: 2rem;
          }

          .h2 {
            font-size: 1.6rem;
          }

          .h3 {
            font-size: 1.25rem;
          }

          .h3s {
            font-size: 1.12rem;
          }

          .h4 {
            font-size: 1rem;
          }

          .h5 {
            font-size: 0.85rem;
          }

          .h6 {
            font-size: 0.7rem;
          }

          .w7 {
            font-weight: 700;
          }

          .w6 {
            font-weight: 600;
          }

          .w5 {
            font-weight: 500;
          }

          .height-text {
            line-height: 1.35rem;
          }

          .image {
            max-width: 150px;
          }

          .image img {
            width: 100%;
          }

          .plus {
            color: #47978a;
          }

          .page-number {
            text-align: end;
            margin-top: 0.25rem;
          }

          .footer .right {
            text-align: right;
          }
         </style>

         ` +
        `</head><body onload="window.print()">` +
        printContent +
        `</body></html>`
    );
    popupWin?.document.close();
  }

  ngOnDestroy() {
    this.printOrdersSubscription?.unsubscribe();
  }
}
