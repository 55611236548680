import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PrintStatementsService } from '../print-statements.service';

@Component({
  selector: 'app-bank-statement-designer',
  templateUrl: './bank-statement-designer.component.html',
  styleUrls: ['./bank-statement-designer.component.scss'],
})
export class BankStatementDesignerComponent implements OnInit {
  bankStatementForm: FormGroup = new FormGroup({
    header_left1: new FormControl(null),
    header_left2: new FormControl(null),
    header_right: new FormControl(null),
    elements: new FormArray([], Validators.required),
    footer_left1: new FormControl(null),
    footer_left2: new FormControl(null),
    footer_left3: new FormControl(null),
    footer_left4: new FormControl(null),
    footer_right1: new FormControl(null),
    footer_right2: new FormControl(null),
    footer_right3: new FormControl(null),
    currentBalance: new FormControl(0),
  });
  image_url: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private printStatementsService: PrintStatementsService
  ) {}

  ngOnInit(): void {}

  getElementsArray() {
    return this.bankStatementForm.get('elements') as FormArray;
  }

  getElementsArrayControls() {
    return (this.bankStatementForm.get('elements') as FormArray).controls;
  }

  addElement() {
    const length = this.bankStatementForm.value.elements.length;

    let { left1, left2, left3, left4, left5, left6, middle, right } = length
      ? this.bankStatementForm.value.elements[
          this.bankStatementForm.value.elements.length - 1
        ]
      : ({} as any);
    const element = this.formBuilder.group({
      left1: new FormControl(false ? left1 : null, Validators.required),
      left2: new FormControl(false ? left2 : null, Validators.required),
      left3: new FormControl(false ? left3 : null, Validators.required),
      left4: new FormControl(false ? left4 : null, Validators.required),
      left5: new FormControl(false ? left5 : null, Validators.required),
      left6: new FormControl(false ? left6 : null, Validators.required),
      middle: new FormControl(false ? middle : null, Validators.required),
      right: new FormControl(false ? right : 0, Validators.required),
    });

    (this.bankStatementForm.get('elements') as FormArray).push(element);
  }

  removeElement(i: number) {
    this.getElementsArray().removeAt(i);
  }

  submit() {
    const {
      header_left1,
      header_left2,
      header_right,
      elements,
      footer_left1,
      footer_left2,
      footer_left3,
      footer_left4,
      footer_right1,
      footer_right2,
      footer_right3,
      currentBalance,
    } = this.bankStatementForm.value;

    this.printStatementsService.print({
      header: {
        left1: header_left1,
        left2: header_left2,
        right: header_right,
      },
      elements,
      footer: {
        left1: footer_left1,
        left2: footer_left2,
        left3: footer_left3,
        left4: footer_left4,
        right1: footer_right1,
        right2: footer_right2,
        right3: footer_right3,
      },
      currentBalance,
    });
  }

  onFileChanged(event: any) {
    const files = event.target.files;
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.bankStatementForm.patchValue({ header_right: reader.result });
      // this.image_url = reader.result as string;
    };
  }
}
