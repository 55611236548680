<input type="file" #uploadFile hidden (change)="onFileChanged($event)" />
<div id="main-container">
  <form [formGroup]="bankStatementForm">
    <h2>Header</h2>
    <div class="element-section">
      <div class="left">
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="header_left1" />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="header_left2" />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="image">
          <img [src]="bankStatementForm.get('header_right')!.value" alt="" />
        </div>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-upload"
          class="p-button-info my-p-button-sm"
          (click)="uploadFile.click()"
        ></button>
      </div>
    </div>

    <hr />

    <div formArrayName="elements">
      <div
        class="element"
        *ngFor="let user of getElementsArrayControls(); let i = index"
      >
        <div [formGroupName]="i" class="element-section">
          <div class="left">
            <div class="row">
              <div class="input-field">
                <input type="text" pInputText formControlName="left1" />
              </div>
            </div>
            <div class="row">
              <div class="input-field">
                <input type="text" pInputText formControlName="left2" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="input-field">
                  <input type="text" pInputText formControlName="left3" />
                </div>
              </div>
              <div class="col">
                <div class="input-field">
                  <input type="text" pInputText formControlName="left4" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="input-field">
                <input type="text" pInputText formControlName="left5" />
              </div>
            </div>
            <div class="row">
              <div class="input-field">
                <input type="text" pInputText formControlName="left6" />
              </div>
            </div>
          </div>
          <div class="middle">
            <div class="input-field">
              <input type="text" pInputText formControlName="middle" />
            </div>
          </div>
          <div class="right">
            <div class="input-field">
              <input type="number" pInputText formControlName="right" />
            </div>
          </div>
        </div>

        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-trash"
          class="p-button-danger p-button-sm"
          (click)="removeElement(i)"
        ></button>
      </div>
    </div>

    <div class="add">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-info my-p-button-sm"
        (click)="addElement()"
      ></button>
    </div>

    <h2>Footer</h2>
    <div class="element-section">
      <div class="left">
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="footer_left1" />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="footer_left2" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-field">
              <input type="text" pInputText formControlName="footer_left3" />
            </div>
          </div>
          <div class="col">
            <div class="input-field">
              <input type="text" pInputText formControlName="footer_left4" />
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="footer_right1" />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="footer_right2" />
          </div>
        </div>
        <div class="row">
          <div class="input-field">
            <input type="text" pInputText formControlName="footer_right3" />
          </div>
        </div>
      </div>
    </div>

    <h2>Current</h2>
    <div class="element-section">
      <div class="left">
        <div class="row">
          <div class="input-field">
            <input type="number" pInputText formControlName="currentBalance" />
          </div>
        </div>
      </div>
    </div>

    <button
      pButton
      pRipple
      type="button"
      class="p-button-info my-p-button-sm"
      label="Print"
      (click)="submit()"
    ></button>
  </form>
</div>

<app-print-bank-statements></app-print-bank-statements>
