import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PrintStatementsService } from '../print-statements.service';
import { Document, DocumentElement } from '../document.interface';

@Component({
  selector: 'app-print-bank-statements',
  templateUrl: './print-bank-statements.component.html',
  styleUrls: ['./print-bank-statements.component.scss'],
})
export class PrintBankStatementsComponent implements OnInit, OnDestroy {
  printOrdersSubscription: Subscription | null = null;
  invoiceBody: any;
  documentsToPrint: Document[] = [];
  positiveSum = 0;
  negativeSum = 0;
  total = 0;
  constructor(private printStatementsService: PrintStatementsService) {}

  ngOnInit(): void {
    this.invoiceBody = document.getElementById('invoice-body');

    this.printOrdersSubscription = this.printStatementsService
      .getPrintOrderSubject()
      .subscribe((document: Document) => {
        this.positiveSum = 0;
        this.negativeSum = 0;
        this.total = 0;
        this.documentsToPrint = [];

        for (let i = 0; i < document.elements.length; i = i + 4) {
          const elements: DocumentElement[] = [];
          for (let j = i; j < i + 4; j++) {
            const element = document.elements[j];
            if (element) {
              elements.push(element);
              if (element.right > 0) {
                this.positiveSum += element.right;
              } else {
                this.negativeSum += element.right;
              }
            }
          }

          const documentToPush: Document = {
            header: document.header,
            footer: document.footer,
            elements,
            currentBalance: document.currentBalance,
          };

          this.documentsToPrint.push(documentToPush);
        }

        this.positiveSum = Number(this.positiveSum.toFixed(2));
        this.negativeSum = Number(this.negativeSum.toFixed(2));
        document.currentBalance = Number(document.currentBalance.toFixed(2));

        this.total = Number(
          (
            this.positiveSum +
            this.negativeSum +
            document.currentBalance
          ).toFixed(2)
        );

        setTimeout(() => this.print(), 2000);
      });
  }

  print() {
    if (!this.invoiceBody) {
      this.invoiceBody = document.getElementById('invoice-body');
    }
    const printContent = this.invoiceBody.innerHTML;
    const popupWin = window.open('', '_blank', 'width=1000,height=800');
    popupWin?.document.open();
    popupWin?.document.write(
      `<html><head>` +
        `
         <style>

          @media print {
            body {-webkit-print-color-adjust: exact;}
          }

          body {
            padding: 4rem;
            max-width: 1000px;
          }

          .flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .items .flex .wide {
            width: 500px;
          }

          .items .flex .narrow {
            width: 250px;
          }

          .items {
            margin: 2rem 0;
          }

          .m-y {
            margin: 1rem 0;
          }

          .start {
            flex: 4;
          }

          .middle {
            flex: 2.5;
          }

          .end {
            flex: 1.75;
          }

          .start, .middle, .end {
            margin: 0 0.1rem;
          }

          p {
            margin: 0.2rem 0;
            font-family: Montserrat;
          }

          .h0 {
            font-size: 3.3rem;
          }

          .h1 {
            font-size: 2rem;
          }

          .h2 {
            font-size: 1.6rem;
          }

          .h3 {
            font-size: 1.25rem;
          }

          .h4 {
            font-size: 1rem;
          }

          .h5 {
            font-size: 0.85rem;
          }

          .h6 {
            font-size: 0.7rem;
          }

          .w7 {
            font-weight: 700;
          }

          .w6 {
            font-weight: 600;
          }

          .w5 {
            font-weight: 500;
          }

          .image {
            max-width: 150px;
          }

          .image img {
            width: 100%;
          }

          .plus {
            color: #50C878;
            font-weight: 600;
          }

         </style>

         ` +
        `</head><body onload="window.print()">` +
        printContent +
        `</body></html>`
    );
    popupWin?.document.close();
  }

  ngOnDestroy() {
    this.printOrdersSubscription?.unsubscribe();
  }
}
